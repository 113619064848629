import { faBoxArchive } from '@fortawesome/pro-regular-svg-icons';
import { withBookmarksApiBookmarkFoldersPath } from '@routes';
import React from 'react';
import {
  Col, Nav, Row, Tab,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import NothingFound from '@/application/components/NothingFound';
import Layout from '@/application/layouts/Layout';
import BookmarkFolderArticles from '@/application/pages/bookmarks/components/BookmarkFolderArticles';
import BookmarkNavItem from '@/application/pages/bookmarks/components/BookmarkNavItem';
import NewBookmarkButton from '@/application/pages/bookmarks/components/NewBookmarkButton';
import LoadingIndicator from '@/shared/components/LoadingIndicator';
import { useApiCollection } from '@/shared/hooks/useApi';
import { BookmarkFolder } from '@/shared/types/models';
import { buildBookmarkFolderTrees } from '@/shared/utilities/bookmarkFolders';

function BookmarkIndex() {
  const { t } = useTranslation();

  const [{ items: combinedFolders }, { loading }, refreshBookmarks] = useApiCollection<BookmarkFolder>({
    url: `${withBookmarksApiBookmarkFoldersPath()}?`,
  });

  const bookmarkFolders: BookmarkFolder[] = combinedFolders.filter(
    (folder) => folder.shared === false,
  );

  const sharedFolders: BookmarkFolder[] = combinedFolders.filter(
    (folder) => folder.shared === true,
  );

  const bookmarkFolderTrees = buildBookmarkFolderTrees(bookmarkFolders);
  const sharedFolderTrees = buildBookmarkFolderTrees(sharedFolders);

  return (
    <Layout>
      {!loading && combinedFolders.length && (
        <Tab.Container defaultActiveKey={combinedFolders[0].id}>
          <Row>
            <Col lg={4}>
              <Nav className="flex-column bookmark-nav mb-3">
                {bookmarkFolderTrees.map((folderNode) => (
                  <BookmarkNavItem
                    key={folderNode.id}
                    folderNode={folderNode}
                    refreshBookmarks={refreshBookmarks}
                  />
                ))}
              </Nav>
              <NewBookmarkButton callbackHandler={refreshBookmarks} />

              {sharedFolders.length > 0 && (
                <>
                  <hr />
                  <h3>{t('bookmarks.folder.share.index.nav')}</h3>
                </>
              )}

              <Nav className="flex-column bookmark-nav mb-3">
                {sharedFolderTrees.map((folderNode) => (
                  <BookmarkNavItem
                    key={folderNode.id}
                    folderNode={folderNode}
                    refreshBookmarks={refreshBookmarks}
                  />
                ))}
              </Nav>

            </Col>
            <Col lg={8}>
              <Tab.Content>
                {combinedFolders.map((folder) => (
                  <BookmarkFolderArticles key={folder.id} {...folder} />
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}

      {loading && <div className="text-center"><LoadingIndicator /></div>}

      {!loading && combinedFolders.length === 0 && (
        <NothingFound
          icon={faBoxArchive}
          title={t('bookmarks.folder.none_exist')}
          subtitle={t('bookmarks.folder.none_exist_subtitle')}
          button={<NewBookmarkButton callbackHandler={refreshBookmarks} />}
        />
      )}
    </Layout>
  );
}

export default BookmarkIndex;
