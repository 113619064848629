import {
  array, number, object, string,
} from 'yup';

export const ContactFormSchema = () => object({
  name: string().max(250).required(),
  phone_number: string().max(20),
  message: string().max(1000).required(),
});

export const ErrorFoundFormSchema = () => object({
  message: string().max(1000).required(),
});

export const BookmarkFolderFormSchema = () => object({
  name: string().max(250).required(),
  parent_id: string().optional(),
});

export const AgendaFeedFormSchema = () => object({
  title: string().max(250).required(),
  agenda_category_ids: array().of(number()),
});
