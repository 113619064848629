import { BookmarkFolder } from '../types/models';

export interface BookmarkFolderTreeNode extends BookmarkFolder {
  subfolders: BookmarkFolderTreeNode[];
}

export const buildBookmarkFolderTrees = (folders: BookmarkFolder[]): BookmarkFolderTreeNode[] => {
  const folderNodes: BookmarkFolderTreeNode[] = folders.map((folder) => ({
    ...folder,
    subfolders: [],
  }));
  const folderNodeMap = folderNodes.reduce((acc, folder) => {
    acc[folder.id] = folder;
    return acc;
  }, {} as Record<string, BookmarkFolderTreeNode>);
  const folderTrees: BookmarkFolderTreeNode[] = folderNodes.filter(
    (folder) => folder.parent_id === null || folderNodeMap[folder.parent_id] === undefined,
  );

  folderNodes.forEach((folderNode) => {
    if (folderNode.parent_id === null) {
      return;
    }
    const parent = folderNodeMap[folderNode.parent_id];
    parent.subfolders.push(folderNode);
  });

  return folderTrees;
};
