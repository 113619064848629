import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CreateBookmarkFolderModal from '@/application/pages/bookmarks/components/CreateBookmarkFolderModal';

export function CreateChildBookmarkFolderButton({ folder, callbackHandler }: any) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <>
      <Dropdown.Item onClick={() => setShow(true)} className="text-neutral-90">
        <FontAwesomeIcon icon={faPlus} className="pe-2 small" />
        {t('bookmarks.folder.add_sub')}
      </Dropdown.Item>
      <CreateBookmarkFolderModal
        showModal={show}
        closeHandler={() => setShow(false)}
        newFolderHandler={callbackHandler}
        parentFolderId={folder.id}
      />
    </>
  );
}
