import { faShare, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import DeleteBookmarkFolderButton from '@/application/pages/bookmarks/components/DeleteBookmarkFolderButton';
import DeleteSharedBookmarkFolderButton
  from '@/application/pages/bookmarks/components/DeleteSharedBookmarkFolderButton';
import EditBookmarkFolderButton from '@/application/pages/bookmarks/components/EditBookmarkFolderButton';
import { BookmarkFolderTreeNode } from '@/shared/utilities/bookmarkFolders';

import { CreateChildBookmarkFolderButton } from './CreateChildBookmarkFolderButton';

const MAX_DEPTH = 3;

const CustomToggle = forwardRef<any, ButtonHTMLAttributes<any>>(({ onClick }, ref) => (
  <button
    className="btn btn-outline-light dossier-edit-button"
    ref={ref}
    type="button"
    onClick={onClick}
    aria-label="toggle"
  >
    <i className="fa-regular fa-ellipsis" />
  </button>
));

type BookmarkNavItemProps = {
  folderNode: BookmarkFolderTreeNode
  refreshBookmarks: () => void
  depth?: number
};

function BookmarkNavItem({
  folderNode, refreshBookmarks, depth = 0,
}: BookmarkNavItemProps) {
  const { t } = useTranslation();

  const shareThroughLink = () => {
    navigator.clipboard.writeText(folderNode.share_url);
    toast.success(t('share.copied_to_clipboard'));
  };

  const shareThroughEmail = () => {
    const subject = t('bookmarks.folder.share.through_email.subject', { folder_name: folderNode.name });
    const body = t(
      'bookmarks.folder.share.through_email.body',
      { user_name: '', folder_name: folderNode.name, invite_url: folderNode.share_url },
    );
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  return (
    <>
      <Nav.Link
        eventKey={folderNode.id}
        className={`d-flex align-items-center justify-content-between cursor-pointer soh-container bookmark-depth-${depth}`}
        as="div"
      >
        <span className="text-break me-3">
          {folderNode.name}
        </span>

        <div className="d-flex align-items-center text-nowrap">
          {folderNode.shared && depth < 1 && (
            <span className="dossier-badge text-dark me-2">
              <FontAwesomeIcon icon={faUser} className="pe-2 small" />
              {folderNode.shared_by}
            </span>
          )}
          <span className="dossier-badge text-dark me-2">{folderNode.bookmarks.length}</span>

          {!folderNode.shared && (
            <Dropdown className="soh-item">
              <Dropdown.Toggle as={CustomToggle} />
              <Dropdown.Menu>
                <EditBookmarkFolderButton folder={folderNode} callbackHandler={refreshBookmarks} />
                {depth < MAX_DEPTH
                  && <CreateChildBookmarkFolderButton folder={folderNode} callbackHandler={refreshBookmarks} />}
                <DeleteBookmarkFolderButton folder={folderNode} callbackHandler={refreshBookmarks} />
                <Dropdown.Divider />
                <Dropdown.Header>{t('title.share')}</Dropdown.Header>

                <Dropdown.Item eventKey="shareLink" onClick={shareThroughLink}>
                  <FontAwesomeIcon icon={faShare} className="pe-2 small" />
                  {t('share.link')}
                </Dropdown.Item>
                <Dropdown.Item eventKey="shareEmail" onClick={shareThroughEmail}>
                  <FontAwesomeIcon icon={faShare} className="pe-2 small" />
                  {t('share.email')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}

          {folderNode.shared && depth < 1 && (
            <Dropdown className="soh-item">
              <Dropdown.Toggle as={CustomToggle} />
              <Dropdown.Menu>
                <DeleteSharedBookmarkFolderButton folder={folderNode} callbackHandler={refreshBookmarks} />
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </Nav.Link>

      {
        folderNode.subfolders.map((child) => (
          <BookmarkNavItem
            key={child.id}
            folderNode={child}
            refreshBookmarks={refreshBookmarks}
            depth={depth + 1}
          />
        ))
      }
    </>
  );
}

export default BookmarkNavItem;
